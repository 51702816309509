import React from 'react';
import { navigate } from 'gatsby';

export const Button = props => {
  const style = {
    backgroundColor: props.bkgColor || '#148BBB',
    borderRadius: props.borderRadius || '8px',
    color: props.color || '#fff',
    width: props.width || '205px',
    height: props.height || '42px',
    boxSizing: 'border-box',
    padding: props.padding || '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  };

  const handleClick = () => {
    props.external
      ? window.open(props.href, '_blank')
      : navigate(`${props.href}`);
  };

  return (
    <div style={style} onClick={handleClick}>
      {props.value}
    </div>
  );
};
