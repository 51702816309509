import React, { Fragment, useState, useEffect, useContext } from 'react';
import StickyISI from './isi-contents/sticky-isi';
import { appContext } from 'providers/appProvider';
import PatientIsi from './isi-contents/PatientIsi';
import HcpIsi from './isi-contents/HcpIsi';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export default function Isi({ footerRef, indication }) {
  const { appConfigs, topOffset, magicshot } = useContext(appContext);
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  const [isSticky, setIsSticky] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedStickyISI, setExpandedStickyISI] = useState(false);
  const [stickyISIHeight, setStickyISIHeight] = useState(
    appConfigs.siteOptions.STICKY_HEIGHT,
  );
  const expandedISIHeight = 600;

  useEffect(() => {
    if (appConfigs.siteOptions.ISI_OPTIONS.TYPE === 'sticky' && !magicshot) {
      setIsSticky(true);
    }
  }, []);

  useEffect(() => {
    if (expandedStickyISI) {
      setStickyISIHeight(expandedISIHeight);
    } else {
      setStickyISIHeight(appConfigs.siteOptions.STICKY_HEIGHT);
    }
  }, [expandedStickyISI]);

  const handleStickyISIClick = () => {
    setExpandedStickyISI(prev => !prev);
  };

  const StickyHeaderTitle = () => {
    return (
      <p className="isi-h1">
        {indication === siteIndications.patient ? (
          <>
            IMPORTANT SAFETY INFORMATION ABOUT SPRIX<sup>&reg;</sup>
          </>
        ) : (
          <>
            IMPORTANT SAFETY INFORMATION ABOUT SPRIX<sup>&reg;</sup>
          </>
        )}
      </p>
    );
  };

  const StickyHeader = () => (
    <div className="sticky-header">
      <StickyHeaderTitle />
      <button
        className="btn-expand-isi gutter-right"
        onClick={handleStickyISIClick}
      >
        <span className="btn-expand-text">
          {!expandedStickyISI ? 'SHOW MORE' : 'SHOW LESS'}
        </span>
        <span className="btn-plus-minu-text">
          {!expandedStickyISI ? '+' : '-'}
        </span>
      </button>
    </div>
  );

  return (
    <Fragment>
      {isSticky ? (
        <StickyISI
          footerRef={footerRef}
          stickyHeight={stickyISIHeight}
          stickyStyles={appConfigs.siteOptions.ISI_OPTIONS.STICKY_STYLES}
          className="isi"
          onFooterStateChange={result => {
            setIsExpanded(result);
          }}
        >
          <div className="isi-content-wrapper">
            <div className="isi-content">
              {!isExpanded && <StickyHeader />}

              {indication === siteIndications.patient ? (
                <PatientIsi isExpanded={isExpanded} />
              ) : (
                <HcpIsi isExpanded={isExpanded} />
              )}
            </div>
          </div>
        </StickyISI>
      ) : (
        <div className="isi">
          <div className="isi-content-wrapper">
            <div className="isi-content">
              {indication === siteIndications.patient ? (
                <PatientIsi />
              ) : (
                <HcpIsi />
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
