import React, { useContext } from 'react';
import ExternalLink from 'components/external-link';
import { appContext } from 'providers/appProvider';
import { findCurrentPathname } from 'libs/utilityHelper';
import 'components/external-link/styles.module.css';

/**
 * Place all of your styles for this components in
 * `scss/default-theme/components/_isi.scss`.
 * */

const isPatientHome = () =>
  window?.location?.pathname === '/patient' ||
  window?.location?.pathname === '/patient/';

const ReferencesBlock = () => {
  return (
    isPatientHome() && (
      <div className="references-block">
        <h4 className="mb-0">References:</h4>
        <div className="ref-item">
          <ol className="ml-0">
            <li className="ref-list">
              <span className="strong">1.</span>
              <div>
                Brown C, Moodie J, Bisley E, Bynum L. Intranasal ketorolac for
                postoperative pain: a phase 3, double-blind, randomized study.{' '}
                <span className="italics">Pain Med.</span> 2009;10(6):1106-1114.
              </div>
            </li>
            <li className="ref-list">
              <span class="strong">2.</span>
              <div>
                Singla N, Singla S, Minkowitz HS, Moodie J, Brown C. Intranasal
                ketorolac for acute postoperative pain.{' '}
                <span className="italics">Curr Med Res Opin.</span>{' '}
                2010;26(8):1915-1923.
              </div>
            </li>
          </ol>
        </div>
      </div>
    )
  );
};

export default function PatientIsi({ isExpanded }) {
  const { appConfigs } = useContext(appContext);

  return (
    <>
      {isExpanded && (
        <p className="isi-h1">
          IMPORTANT SAFETY INFORMATION ABOUT SPRIX<sup>&reg;</sup>
        </p>
      )}
      <div className="body-content-container">
        <div className="body-content">
          <section>
            <h4>
              What is the most important information I should know about SPRIX
              and prescription Nonsteroidal Anti-inflammatory Drugs (NSAIDs)?
            </h4>
            <p className="no-padding-bottom">
              Sprix (ketorolac tromethamine) Nasal Spray is an NSAID.
            </p>
            <p className="no-padding-bottom">
              NSAIDs can cause serious side effects including, increased risk of
              a heart attack or stroke that can lead to death. This risk may
              happen early in treatment <br className="hidden-smaller-screen" />{' '}
              and may increase with increasing doses of NSAIDs and with longer
              use of NSAIDs.
            </p>
            <p>
              NSAIDs can also cause increased risk of bleeding, ulcers, and
              tears (perforation) of the esophagus (tube leading from the mouth
              to the stomach), stomach
              <br className="hidden-smaller-screen" /> and intestines which may
              occur anytime during use, without warning symptoms, and may cause
              death.
            </p>
            <p>
              See the{' '}
              <span class="strong">
                Boxed Warning: Risk of Serious Cardiovascular and
                Gastrointestinal Events
              </span>
            </p>
            <p>
              Do not take SPRIX right before or after a heart surgery called a
              “coronary artery bypass graft (CABG).”
            </p>
            <p>
              Do not take SPRIX after a recent heart attack, unless your
              healthcare provider tells you to. You may have an increased risk
              of another heart attack if you
              <br className="hidden-smaller-screen" /> take NSAIDs after a
              recent heart attack.
            </p>
            <p>The risk of getting an ulcer or bleeding also increases with:</p>
            <ul className="open-circle-list">
              <li>
                Taking medicines called “corticosteroids”, “anticoagulants”,
                “SSRIs”, or “SNRIs”
              </li>
              <li>Increasing doses of NSAIDs</li>
              <li>Longer use of NSAIDs</li>
              <li>Smoking</li>
              <li>Drinking alcohol</li>
              <li>Poor health</li>
              <li>Advanced liver disease</li>
              <li>Bleeding problems</li>
            </ul>
            <p>
              <span className="strong">SPRIX should only be used:</span>
            </p>
            <ul>
              <li>Exactly as prescribed</li>
              <li>At the lowest dose possible for your treatment</li>
              <li>For the shortest time needed</li>
            </ul>
            <p>
              <span className="strong">
                What is SPRIX<sup>&reg;</sup>?
              </span>
            </p>
            <p>
              SPRIX<sup>&reg;</sup> (ketorolac tromethamine) is used in adult
              patients for the short term (up to 5 days) management of moderate
              to moderately severe pain that requires analgesia at the opioid
              level.
            </p>
            <p>Limitations of Use</p>
            <ul>
              <li>
                Sprix is not for use in pediatric patients less than 2 years of
                age.
              </li>
            </ul>
            <p>
              <span className="strong">
                Do not use SPRIX<sup>&reg;</sup>:
              </span>
            </p>
            <ul>
              <li>
                If you are allergic to ketorolac or any components of the drug
                product.
              </li>
              <li>
                If you have had an asthma attack, hives, or other allergic
                reaction with aspirin or any other NSAIDs.
              </li>
              <li>Right before or after heart bypass surgery </li>
              <li>
                If you have active ulcer disease or any history of bleeding in
                the stomach or intestines
              </li>
              <li>
                If you have had bleeding in the brain, a tendency to bleed, or
                have decreased ability for your blood to clot
              </li>
              <li>If you have kidney disease</li>
              <li>If you are taking probenecid or pentoxifylline </li>
              <li>
                For more than 5 days or with other formulations of ketorolac or
                with other NSAIDs
              </li>
            </ul>
            <p>
              <span className="strong">
                Before taking SPRIX or any NSAID, tell your healthcare provider
                about all of your medical conditions, including if you:
              </span>
            </p>
            <ul className="patient-isi">
              <li>have liver or kidney problems</li>
              <li>have high blood pressure or heart failure</li>
              <li>have asthma</li>
              <li>have bleeding problems</li>
              <li>
                <div>
                  are pregnant or plan to become pregnant. Taking NSAIDs,
                  including SPRIX at about 20 weeks of pregnancy or later may
                  harm your unborn baby.
                  <br className="hidden-smaller-screen" /> If you need to take
                  NSAIDs for more than 2 days when you are between 20 and 30
                  weeks of pregnancy, your healthcare provider may
                  <br className="hidden-smaller-screen" />
                  need to monitor the amount of fluid in your womb around your
                  baby.
                  <span className="strong">
                    {'  '}
                    You should NOT take SPRIX, after about 30 weeks of
                    pregnancy.
                  </span>
                </div>
              </li>
              <li>
                Are breastfeeding or plan to breast feed. Some SPRIX can pass
                into your breast milk.
              </li>
            </ul>
            <p>
              <span className="strong">
                Tell your healthcare provider about all of the medicines you
                take, including prescription or over-the-counter medicines,
                vitamins or herbal supplements. SPRIX and some other medicines
                can interact with each other and cause serious side effects. Do
                not start taking any new medicine without talking to your
                healthcare provider first.
              </span>
            </p>
            <h4 clasName="larger-headline">
              What are the possible side effects of SPRIX?
            </h4>
            <h4 className="smaller-headline">
              See “What is the most important information I should know about
              SPRIX and prescription Nonsteroidal Anti-inflammatory Drugs
              (NSAIDs)?”
            </h4>
            <ul>
              <li>new or worse high blood pressure</li>
              <li>heart failure</li>
              <li>liver problems including liver failure</li>
              <li>kidney problems including kidney failure</li>
              <li>low red blood cells (anemia)</li>
              <li>life-threatening skin reactions</li>
              <li>life-threatening allergic reactions</li>
            </ul>
            <p>
              <span className="strong">
                Other side effects of NSAIDs include:
              </span>{' '}
              stomach pain, constipation, diarrhea, gas, heartburn, nausea,
              vomiting, and dizziness.
            </p>
            <p>
              <span className="strong">
                Most common side effects with SPRIX include:
              </span>{' '}
              discomfort in the nose; pain in the nose; increased tears; throat
              irritation; decreased amount of urine;
              <br className="hidden-smaller-screen" /> rash; slow heart rate;
              increased liver enzymes; high blood pressure; stuffy nose.
            </p>
            <p>
              Keep SPRIX away from your eyes. If you get SPRIX in your eyes,
              wash out the eye with water or saline, and call a doctor if
              irritation persists for more than{' '}
              <br className="hidden-smaller-screen" /> an hour.
            </p>
            <h4 className="small-headline">
              Get emergency help right away if you get any of the following
              symptoms:
            </h4>
            <ul>
              <li>Shortness of breath or trouble breathing</li>
              <li>chest pain</li>
              <li>weakness in one part or side of your body</li>
              <li>slurred speech</li>
              <li>swelling of the face or throat</li>
            </ul>
            <h4 className="small-headline">
              Stop taking SPRIX and call your healthcare provider right away if
              you get any of the following symptoms:
            </h4>
            <ul>
              <li>nausea</li>
              <li>more tired or weaker than usual</li>
              <li>diarrhea</li>
              <li>itching</li>
              <li>your skin or eyes look yellow</li>
              <li>indigestion or stomach pain</li>
              <li>flu-like symptoms</li>
              <li>vomit blood</li>
              <li>
                there is blood in your bowel movement or it is black and sticky
                like tar
              </li>
              <li>unusual weight gain</li>
              <li>any type of rash or fever</li>
              <li>swelling of the arms, legs, hands and feet</li>
            </ul>
            <h4 className="small-headline">
              If you take too much SPRIX, call your healthcare provider or get
              medical help right away.
            </h4>
            <p className="no-pb">
              These are not all the possible side effects of NSAIDs. For more
              information, ask your healthcare provider or pharmacist about
              NSAIDs.
            </p>
            <p className="no-pt">
              <strong>
                Call your doctor for medical advice about side effects. You may
                report side effects to FDA at <nobr>1-800-FDA-1088</nobr>.
              </strong>
            </p>
            <h4 className="small-headline">
              Please see{' '}
              <a
                href={
                  'https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=97005a1c-167e-4676-bae7-e49b38c36f9e'
                }
                target="_blank"
                className="light-blue"
              >
                full Prescribing Information
              </a>
              , including Boxed Warning and patient Medication Guide.
            </h4>
            <ReferencesBlock />
          </section>
        </div>
      </div>
    </>
  );
}
