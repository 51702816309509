import React, { useContext, useRef, useEffect } from 'react';
import { Logo } from 'components';
import Nav from './nav';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { appContext } from 'providers/appProvider';
import { useResizeObserver } from 'hooks';
import { PatientIndicationUsage } from './indication-usuage/Patient';
import { HcpIndicationUsage } from './indication-usuage/Hcp';
import { HcpSubNav } from './nav/sub-nav/HCPNav';
import { PatientSubNav } from './nav/sub-nav/PatientNav';
import { HcpHeaderContent } from 'components/layout/layout-blocks/header/HcpHeaderContent';
import { PatientHeaderContent } from 'components/layout/layout-blocks/header/PatientHeaderContent';

const PatientHeader = ({ indication }) => {
  return (
    <div className="main-header-content-wrapper">
      <div className="gutter-all header-content">
        <PatientHeaderContent />
      </div>
    </div>
  );
};

const HcpHeader = ({ indication }) => {
  return (
    <div className="main-header-content-wrapper">
      <div className="gutter-all header-content">
        <HcpHeaderContent />
      </div>
    </div>
  );
};

const HeaderUtilityNav = ({ className, indication, ...props }) => {
  const { appConfigs } = useContext(appContext);
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  return (
    <div {...props} className={className}>
      <div className="header-utility-content-wrapper">
        <div className="header-content">
          <ul className="utility-list">
            <li className={siteIndications.hcp === indication ? 'active' : ''}>
              <Link
                to="/"
                className={siteIndications.hcp === indication ? 'active' : ''}
              >
                HCPs
              </Link>
            </li>
            <li
              className={siteIndications.patient === indication ? 'active' : ''}
            >
              <Link
                to="/patient"
                className={
                  siteIndications.patient === indication ? 'active' : ''
                }
              >
                PATIENTS
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default function Header({ indication, ...props }) {
  const {
    showIndicationUsage,
    updateHeaderHeight,
    magicshot,
    appConfigs,
  } = useContext(appContext);

  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  const headerRef = useRef(null);
  const elementSize = useResizeObserver(headerRef);
  const isHeaderFixed = appConfigs.siteOptions.HEADER_TYPE === 'fixed';

  useEffect(() => {
    isHeaderFixed && updateHeaderHeight(elementSize.height);
  }, [elementSize.height]);

  const renderIndication = () =>
    indication === siteIndications.hcp ? (
      <HcpIndicationUsage />
    ) : (
      <PatientIndicationUsage />
    );

  const RenderHeader = () =>
    indication === siteIndications.hcp ? (
      <>
        <HcpHeader indication={indication} />
        <HcpSubNav />
      </>
    ) : (
      <>
        <PatientHeader indication={indication} />
        <PatientSubNav />
      </>
    );

  return (
    <header {...props} className="header-wrapper" ref={headerRef}>
      {/* {showIndicationUsage && renderIndication()} */}
      {renderIndication()}
      <HeaderUtilityNav
        className="header-utility-nav desktop"
        indication={indication}
      />
      <RenderHeader />
    </header>
  );
}

Header.propTypes = {
  indication: PropTypes.string.isRequired,
};
