import React from 'react';
import Modal from 'react-awesome-modal';

import './enter-hcp.scss';

export const EnterHcpModal = ({
  open,
  closeModal,
  onHcpHandler,
  onPatientHandler,
}) => {
  return (
    <div className="enter-hcp-modal-container">
      <Modal visible={open} onClickAway={() => closeModal()}>
        <div className="enter-hcp-container">
          <div className="enter-hcp-header"></div>
          <div className="enter-hcp-content">
            <h2>
              YOU ARE NOW ENTERING A US
              <br /> HEALTHCARE PROFESSIONAL SITE.
            </h2>
            <p>
              The information contained on this site is scientific in nature and
              was designed specifically for US Healthcare Professionals.
            </p>
          </div>
          <div className="enter-hcp-footer">
            <button onClick={onHcpHandler}>
              YES, I AM A US HEALTHCARE PROFESSIONAL
            </button>
            <button onClick={onPatientHandler}>
              NO, I AM NOT A US HEALTHCARE PROFESSIONAL
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
