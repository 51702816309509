import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './index.scss';

const Plus = () => (
  <StaticImage
    src="../../../../../images/plus.svg"
    placeholder="none"
    alt="Plus Icon"
  />
);

const Minus = () => (
  <StaticImage
    src="../../../../../images/minus.png"
    placeholder="none"
    alt="Minus Icon"
  />
);

export const HcpIndicationUsage = props => {
  const [expand, setExpand] = useState(true);

  const handleExpandCollapse = () => {
    setExpand(prev => !prev);
  };

  return (
    <div className="indication-usuage">
      <div className="indication-usuage-inner-content gutter-all">
        <div class="indication-header">
          <h4 className="no-padding-top">Indications and Usage</h4>
          <div className="btn-container" onClick={handleExpandCollapse}>
            {expand ? <Minus /> : <Plus />}
          </div>
        </div>
        <div
          className={expand ? 'indication-body show' : 'indication-body hide'}
        >
          <p className="no-padding-top no-padding-bottom">
            SPRIX<sup>&reg;</sup> (ketorolac tromethamine) is indicated in adult
            patients for the short term (up to 5 days) management of moderate to
            <br className="hidden-smaller-screen" /> moderately severe pain that
            requires analgesia at the opioid level.
          </p>
          <div className="limitation">
            Limitations of Use
            <ul>
              <li>
                Sprix is not for use in pediatric patients less than 2 years of
                age.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
