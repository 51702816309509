import React, { useState, useEffect, useRef, useContext } from 'react';
import Helmet from 'react-helmet';
import ScrollToTop from 'react-scroll-up';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { useCookies } from 'react-cookie';
import { StaticImage } from 'gatsby-plugin-image';

import { Header, Body, Footer, Isi } from './layout-blocks';
import { appContext } from 'providers/appProvider';
// import { Modal } from 'components/simple-overlay';
import { EnterHcpModal } from 'components/modals/EnterHcp';
import { checkUrlParamFor } from 'libs/utilityHelper';

import 'components/simple-overlay/styles.module.css';
import 'scss/default-theme/theme.scss';

const currentWindowSize = () =>
  typeof window !== 'undefined' && window.innerWidth;

const determinePosition = () => (currentWindowSize() - 1200) / 2 + 16;

export default function Layout({ indication, children, className, ...props }) {
  const {
    updateCurrentIndication,
    updateMagicshot,
    headerHeight,
    appConfigs,
    magicshot,
  } = useContext(appContext);

  const styles = {
    bottom: 20,
    right: 30,
    zIndex: 100,
  };

  const cookiesValue = 'hcp-site-visited';
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  const footerRef = useRef(null);
  const [elmRef, setElmRef] = useState(null);
  const [showHcpModal, setShowHcpModal] = useState(false);
  const [cookies, setCookie] = useCookies([cookiesValue]);
  const [viewportWidth, setViewportWidth] = useState(currentWindowSize());
  const [positionScrollTop, setPositionScrollTop] = useState(styles);

  const isHcpCookieSet = () => {
    const cookieFlag = cookies[cookiesValue];
    if (!cookieFlag) {
      setShowHcpModal(true);
    }
    return cookieFlag;
  };

  const isMagicShotAdded = () => {
    const magicFlag = checkUrlParamFor('magicshot');
    // update the context
    magicFlag && updateMagicshot(true);
    return magicFlag;
  };

  const handlePatientClick = () => {
    document.referrer !== '' ? window.history.back() : navigate('/patient');
    setShowHcpModal(false);
  };

  const handleHcpClick = () => {
    setCookie(cookiesValue, 'visited', { path: '/' });
    setShowHcpModal(false);
  };

  const handleCloseEnterHcpModal = () => {
    setShowHcpModal(false);
  };

  const updateCurrentWidth = () => {
    if (currentWindowSize() > 1200) {
      setPositionScrollTop(prev => {
        return {
          ...prev,
          right: determinePosition(),
        };
      });
    } else {
      setPositionScrollTop(prev => {
        return {
          ...prev,
          right: 30,
        };
      });
    }
  };

  useEffect(() => {
    setElmRef(footerRef.current);
    updateCurrentIndication(indication);
    if (!isMagicShotAdded() && indication === siteIndications.hcp) {
      isHcpCookieSet();
    }
    window.addEventListener('resize', updateCurrentWidth);
    if (viewportWidth > 1200) {
      setPositionScrollTop(prev => {
        return {
          ...prev,
          right: determinePosition(),
        };
      });
    }
  }, []);

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: `page ${indication} ${className}`,
        }}
      />
      <div {...props} className="content-body-wrapper">
        <div className="content-body">
          <Header indication={indication} />
          <Body indication={indication}>
            {children}

            <ScrollToTop
              showUnder={160}
              style={positionScrollTop}
              className="arrow-scroll"
            >
              <div className="scroll-top-container">
                <StaticImage
                  src="../../../images/round-up-arrow.svg"
                  placeholder="none"
                  className="scrollToTopArrow"
                  alt="Up arrrow"
                />
              </div>
            </ScrollToTop>
          </Body>
          <EnterHcpModal
            open={showHcpModal}
            closeModal={handleCloseEnterHcpModal}
            onPatientHandler={handlePatientClick}
            onHcpHandler={handleHcpClick}
          />
          {elmRef && <Isi footerRef={elmRef} indication={indication} />}
          <div ref={footerRef}>
            <Footer indication={indication} />
          </div>
        </div>
      </div>
    </>
  );
}

Layout.propTypes = {
  indication: PropTypes.oneOf(['hcp', 'patient']).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
