import React, { useState, useEffect } from 'react';
import NavBar from 'components/nav-bar';
// import '@ilama007/nav-bar/dist/index.css';
import PropTypes from 'prop-types';
import HcpNav from './nav-contents/HcpNav';
import PatientNav from './nav-contents/PatientNav';

export default function Nav({ indication }) {
  const [triggerClose, setTriggerClose] = useState(false);
  const mobileBreakPoint = 991;

  const closeHamburger = () => {
    setTriggerClose(true);
  };

  return (
    <NavBar
      mobileBreakPoint={mobileBreakPoint}
      triggerHamburgerClose={triggerClose}
    >
      {indication === 'patient' && <PatientNav triggerClose={closeHamburger} />}
      {indication === 'hcp' && <HcpNav triggerClose={closeHamburger} />}
    </NavBar>
  );
}

Nav.propTypes = {
  indication: PropTypes.string.isRequired,
};
