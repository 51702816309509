import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes, { element } from 'prop-types';
import { Link } from 'gatsby';
import { useResizeObserver } from 'hooks';
import { appContext } from 'providers/appProvider';

export default function PatientNav(props) {
  const mobileBreakPoint = 991;
  const { updateNavWidth } = useContext(appContext);
  const [patientHomeActive, setIsPatientHomeActive] = useState(false);
  const [whatAreNsaidsActive, setWhatAreNsaidsActive] = useState(false);
  const [aboutSprixActive, setAboutSprixActive] = useState(false);
  const [sprixDirectActive, setSprixDirectActive] = useState(false);
  const [resourcesActive, setResourcesActive] = useState(false);
  const [getSprixNowActive, setGetSprixNowActive] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);

  const isMobile =
    typeof window !== 'undefined' && window.innerWidth < mobileBreakPoint;

  const navRef = useRef(null);
  let elementSize = useResizeObserver(navRef);

  const handleClick = () => {
    props.triggerClose && props.triggerClose();
  };
  const getCurrentPath = () =>
    typeof window !== 'undefined' && window.location.pathname;

  const isPatientHomeActive = () =>
    ['/patient/', '/patient'].includes(getCurrentPath()) &&
    getCurrentPath() !== '/';

  const isWhatAreNsaidsActive = () =>
    ['/patient/what-are-nsaids/', '/patient/what-are-nsaids'].includes(
      getCurrentPath(),
    ) && getCurrentPath() !== '/';

  const isAboutSprixActive = () =>
    ['/patient/about-sprix/', '/patient/about-sprix'].includes(
      getCurrentPath(),
    ) && getCurrentPath() !== '/';

  const isSprixDirectActive = () =>
    ['/patient/sprix-direct/', '/patient/sprix-direct'].includes(
      getCurrentPath(),
    ) && getCurrentPath() !== '/';

  const isResourcesActive = () =>
    ['/patient/resources/', '/patient/resources'].includes(getCurrentPath()) &&
    getCurrentPath() !== '/';

  const isGetSprixNowActive = () =>
    ['/patient/get-sprix-now/', '/patient/get-sprix-now/'].includes(
      getCurrentPath(),
    ) && getCurrentPath() !== '/';

  useEffect(() => {
    setIsPatientHomeActive(isPatientHomeActive());
    setWhatAreNsaidsActive(isWhatAreNsaidsActive());
    setAboutSprixActive(isAboutSprixActive());
    setSprixDirectActive(isSprixDirectActive());
    setResourcesActive(isResourcesActive());
    setGetSprixNowActive(isGetSprixNowActive());
    setIsLoaded(true);
    isLoaded && elementSize?.current && updateNavWidth(elementSize.width);
    return () => {
      setIsLoaded(false);
    };
  }, []);

  useEffect(() => {
    setIsPatientHomeActive(isPatientHomeActive());
    setWhatAreNsaidsActive(isWhatAreNsaidsActive());
    setAboutSprixActive(isAboutSprixActive());
    setSprixDirectActive(isSprixDirectActive());
    setResourcesActive(isResourcesActive());
    setGetSprixNowActive(isGetSprixNowActive());
  });

  useEffect(() => {
    isLoaded && elementSize?.width && updateNavWidth(elementSize.width);
  }, [elementSize.width]);

  const renderNav = () => {
    return (
      <nav className="main-nav patient-nav" ref={navRef}>
        <Link
          to="/patient"
          className={patientHomeActive ? 'nav-item active' : 'nav-item'}
          activeClassName="active"
          onClick={handleClick}
        >
          HOME
        </Link>
        <span className="pipe-separator">|</span>
        <Link
          to="/patient/what-are-nsaids"
          className={whatAreNsaidsActive ? 'nav-item active' : 'nav-item'}
          activeClassName="active"
          onClick={handleClick}
        >
          WHAT ARE NSAIDs
        </Link>
        <span className="pipe-separator">|</span>
        <Link
          to="/patient/about-sprix"
          className={aboutSprixActive ? 'nav-item active' : 'nav-item'}
          activeClassName="active"
          onClick={handleClick}
        >
          ABOUT SPRIX
        </Link>
        <span className="pipe-separator">|</span>
        <Link
          to="/patient/sprix-direct"
          className={sprixDirectActive ? 'nav-item active' : 'nav-item'}
          activeClassName="active"
          onClick={handleClick}
        >
          SPRIX DIRECT
        </Link>
        <span className="pipe-separator">|</span>
        <Link
          to="/patient/resources"
          className={resourcesActive ? 'nav-item active' : 'nav-item'}
          activeClassName="active"
          onClick={handleClick}
        >
          RESOURCES
        </Link>
        <span className="pipe-separator">|</span>
        <Link
          to="/patient/get-sprix-now"
          className={
            getSprixNowActive
              ? 'nav-item no-right-margin active'
              : 'nav-item no-right-margin'
          }
          activeClassName="active"
          onClick={handleClick}
        >
          GET SPRIX NOW
        </Link>
        {/* <span className="pipe-separator"></span> */}
        <Link
          to="/patient/important-safety-information"
          className="nav-item hidden-desktop no-border-bottom brand-blue"
          activeClassName="active"
          onClick={handleClick}
        >
          Important Safety Information
        </Link>
        <a
          href="https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=97005a1c-167e-4676-bae7-e49b38c36f9e"
          className="nav-item hidden-desktop no-border-bottom no-border-top brand-blue"
          activeClassName="active"
          onClick={handleClick}
          target="_blank"
        >
          Full Prescribing Information
        </a>
        <a
          href="https://dailymed.nlm.nih.gov/dailymed/medguide.cfm?setid=97005a1c-167e-4676-bae7-e49b38c36f9e"
          className="nav-item hidden-desktop no-border-bottom no-border-top brand-blue"
          activeClassName="active"
          onClick={handleClick}
          target="_blank"
        >
          Medication Guide
        </a>
      </nav>
    );
  };

  return isMobile ? isLoaded && renderNav() : renderNav();
}

/**
 * triggerClose is the methid propagated from the parent Nav component
 * It needs to get triggered sometimes when Link refers to the same page and
 * we need to manually trigger hamburger close
 */

PatientNav.propTypes = {
  triggerClose: PropTypes.func,
};
