import React, { useState, useEffect, Fragment } from 'react';

const referencesList = {
  home: [
    'SPRIX<sup>&reg;</sup> [package insert]. Wayne, PA: Zyla Life Sciences; 2021.',
    'Brown C, Moodie J, Bisley E, Bynum L. Intranasal ketorolac for postoperative pain: a phase 3, double-blind, randomized study. <span class="italic">Pain Med.</span> 2009;10(6):1106-1114.',
    'Singla N, Singla S, Minkowitz HS, Moodie J, Brown C. Intranasal ketorolac for acute postoperative pain. <span class="italic">Curr Med Res Opin.</span> 2010;26(8):1915-1923.',
    'Data on file. Assertio Therapeutics, Inc.',
  ],
  'clinical-data': [
    'McAleer SD, Majid O, Venables E, Polack T, Sheikh MS. Pharmacokinetics and safety of ketorolac following single intranasal and intramuscular administration in healthy volunteers. <span class="italic">J Clin Pharmacol.</span> 2007;47(1):13-18.',
    'SPRIX<sup>&reg;</sup> [package insert]. Wayne, PA: Zyla Life Sciences; 2019.',
    'Brown C, Moodie J, Bisley E, Bynum L. Intranasal ketorolac for postoperative pain: a phase 3, double-blind, randomized study. <span class="italic">Pain Med.</span> 2009;10(6):1106-1114. ',
    'Singla N, Singla S, Minkowitz HS, Moodie J, Brown C. Intranasal ketorolac for acute postoperative pain. <span class="italic">Curr Med Res Opin.</span> 2010;26(8):1915-1923.',
    'Data on file. Zyla Life Sciences, Wayne, PA.',
  ],
  'prescribing-sprix': [
    'SPRIX<sup>&reg;</sup> [package insert]. Wayne, PA: Zyla Life Sciences; 2019.',
  ],
  resources: false,
  'get-in-touch': false,
  sitemap: false,
  'important-safety-information': false,
};

const findPageName = () => {
  const currentPage = typeof window !== 'undefined' && window.location.pathname;
  const name =
    currentPage === '/' ? 'home' : currentPage?.replace(/^\/|\/$/g, '');
  return name;
};

export const HcpReferences = () => {
  const references = referencesList[findPageName()] || [];

  const renderTitle = () =>
    references.length > 1 ? (
      <h4 className="mb-0">References:</h4>
    ) : (
      <h4>Reference</h4>
    );

  const displayTitle = () => (references.length > 0 ? renderTitle() : '');

  const fetchReferences = curPageRef => {
    return (
      <div className="ref-item">
        <ol className="ml-0">
          {curPageRef.map((ref, index) => {
            const list = (
              <>
                <span class="strong">{index + 1}.</span>
                <div dangerouslySetInnerHTML={{ __html: ref }} />
              </>
            );
            return (
              <li className="ref-list" key={index}>
                {list}
              </li>
            );
          })}
        </ol>
      </div>
    );
  };

  const renderReferences = () =>
    references.length > 0 ? <>{fetchReferences(references)}</> : <></>;

  return references?.length > 0 ? (
    <div className="references-block">
      {displayTitle()}
      {renderReferences()}
    </div>
  ) : (
    <></>
  );
};
