import React, { useContext } from 'react';
import ExternalLink from 'components/external-link';
import { appContext } from 'providers/appProvider';
import 'components/external-link/styles.module.css';
import ContentBlock from 'components/content-block';
import { HcpReferences } from './HcpReferences';
import { CommonContainer } from 'components/common-container';
/**
 * Place all of your styles for this components in
 * `scss/default-theme/components/_isi.scss`.
 * */

export default function HcpIsi({ isExpanded }) {
  const { appConfigs } = useContext(appContext);
  return (
    <>
      {isExpanded && (
        <p className="isi-h1">
          IMPORTANT SAFETY INFORMATION ABOUT SPRIX<sup>&reg;</sup>
        </p>
      )}
      <div className="body-content-container">
        <div className={`body-content`}>
          <section>
            <div className="black-box-container">
              <h2 className="standard-top-spacing no-margin-bottom">
                WARNING: RISK OF SERIOUS CARDIOVASCULAR AND GASTROINTESTINAL
                EVENTS
              </h2>
              <h3 className="underline">Cardiovascular Thrombotic Events</h3>
              <ul>
                <li>
                  <span>
                    Nonsteroidal anti-inflammatory drugs (NSAIDS) cause an
                    increased risk of serious cardiovascular thrombotic events,
                    including myocardial infarction and stroke, which can be
                    fatal. This risk may occur early in treatment and may
                    increase with duration of use.
                  </span>
                </li>
                <li>
                  <span>
                    SPRIX<sup>®</sup> is contraindicated in the setting of
                    coronary artery bypass graft (CABG) surgery.
                  </span>
                </li>
              </ul>
              <h3 className="underline">
                Gastrointestinal Bleeding, Ulceration, and Perforation
              </h3>
              <ul>
                <li>
                  <span>
                    NSAIDS cause an increased risk of serious gastrointestinal
                    (GI) adverse events including bleeding, ulceration, and
                    perforation of the stomach or intestines, which can be
                    fatal. These events can occur at any time during use and
                    without warning symptoms. Elderly patients and patients with
                    a prior history of peptic ulcer disease and/or GI bleeding
                    are at greater risk for serious GI events.
                  </span>
                </li>
              </ul>
            </div>
            <p className="bold">
              Use the lowest effective dosage for shortest duration consistent
              with individual treatment goals.
            </p>
            <h4 className="standard-top-spacing font-20">
              Indications and Usage
            </h4>
            <p>
              SPRIX<sup>®</sup> (ketorolac tromethamine) is indicated in adult
              patients for the short term (up to 5 days) management of moderate
              to moderately severe pain that requires analgesia at the opioid
              level.
            </p>
            <p>Limitations of Use</p>
            <ul>
              <li>
                Sprix is not for use in pediatric patients less than 2 years of
                age.
              </li>
            </ul>
            <h4 className="standard-top-spacing font-20">
              IMPORTANT SAFETY INFORMATION (continued)
            </h4>
            <h4 className="standard-top-spacing">Contraindications</h4>
            <p>SPRIX is contraindicated in the following patients:</p>
            <ul className="mb-16">
              <li>
                <span>
                  Known hypersensitivity to ketorolac or any components of the
                  drug product.
                </span>
              </li>
              <li>
                <span>
                  History of asthma, urticaria, or other allergic-type reactions
                  after taking aspirin or other NSAIDs. Severe, sometimes fatal,
                  anaphylactic reactions
                  <br className="hidden-smaller-screen" /> to NSAIDs have been
                  reported in such patients.
                </span>
              </li>
              <li>
                <span>
                  In the setting of coronary artery bypass graft (CABG) surgery.
                </span>
              </li>
              <li>
                <span>
                  Use in patients with active peptic ulcer disease or with
                  recent gastrointestinal bleeding or perforation.
                </span>{' '}
              </li>
              <li>
                <span>
                  Use as a prophylactic analgesic before any major surgery.
                </span>
              </li>
              <li>
                <span>
                  Use in patients with advanced renal disease or patients at
                  risk for renal failure due to volume depletion.
                </span>{' '}
              </li>
              <li>
                <span>
                  Use in labor and delivery. May adversely affect fetal
                  circulation and inhibit uterine contractions, thus increasing
                  the risk of uterine hemorrhage.
                </span>
              </li>
              <li>
                <span>
                  Use in patients with suspected or confirmed cerebrovascular
                  bleeding, hemorrhagic diathesis, incomplete hemostasis, or
                  those for whom hemostasis is critical.
                </span>
              </li>
              <li>
                <span>Concomitant use with probenecid or pentoxifylline.</span>
              </li>
            </ul>
            <h4 className="mt-25 fs-12">Warnings and Precautions</h4>
            <p>
              <span className="underline">
                Post-Coronary Artery Bypass Graft (CABG) Surgery
              </span>
              : Clinical trials of a COX-2 selective NSAID used to treat pain in
              the first 10-14 days following CABG surgery found an increased
              incidence of MI and stroke. NSAIDs are contraindicated in the
              setting of CABG.
            </p>
            <p>
              <span className="underline">Post-MI Patients</span>: Avoid the use
              of SPRIX in patients with a recent MI unless the benefits are
              expected to outweigh the risk of recurrent CV thrombotic events.
              If SPRIX is used in patients with a recent MI, monitor patients
              for signs of cardiac ischemia.
            </p>
            <p>
              <span className="underline">
                Gastrointestinal Bleeding, Ulceration, and Perforation
              </span>
              : SPRIX is contraindicated in patients with active peptic ulcers
              and/or GI bleeding, and in patients with recent GI bleeding or
              perforation. NSAIDs, including SPRIX, cause serious GI adverse
              events including inflammation, bleeding, ulceration, and
              perforation of the esophagus, stomach, small intestine, or large
              intestine, which can be fatal. These serious adverse events can
              occur at any time, with or without warning symptoms.
            </p>
            <p>
              <span className="underline">Hepatotoxicity</span>: Elevations of
              ALT or AST have been reported in patients with NSAIDs. In
              addition, rare, sometimes fatal, cases of severe hepatic injury,
              including fulminant hepatitis, liver necrosis, and hepatic failure
              have been reported. Inform patients of warning signs and symptoms
              of hepatotoxicity. Discontinue immediately if abnormal liver tests
              persist or worsen or if clinical signs and symptoms of liver
              disease develop.
            </p>
            <p>
              <span className="underline">Hypertension</span>: NSAIDs, including
              SPRIX, can lead to new onset or worsening of preexisting
              hypertension either of which may contribute to the increased
              incidence of CV events. Patients taking some antihypertensive
              medications may have impaired response to these therapies when
              taking NSAIDs. Monitor blood pressure.
            </p>
            <p>
              <span className="underline">Heart Failure and Edema</span>: Avoid
              use of SPRIX in patients with severe heart failure unless benefits
              are expected to outweigh risk of worsening heart failure. If SPRIX
              is used in patients with severe heart failure, monitor patients
              for signs of worsening heart failure.
            </p>
            <p>
              <span className="underline">Renal Toxicity and Hyperkalemia</span>
              : Long-term administration of NSAIDs has resulted in renal
              papillary necrosis and other renal injury and may cause a
              dose-dependent reduction in prostaglandin formation, which may
              precipitate overt renal decompensation. Monitor renal function in
              patients with renal or hepatic impairment, heart failure,
              dehydration, or hypovolemia. Avoid use of SPRIX in patients with
              advanced renal disease unless benefits are expected to outweigh
              risk of worsening renal function.
            </p>
            <p>
              <span className="underline">Anaphylactic Reactions</span>: Seek
              emergency help if an anaphylactic reaction occurs.
            </p>
            <p>
              <span className="underline">
                Exacerbation of Asthma Related to Aspirin Sensitivity
              </span>
              : SPRIX is contraindicated in patients with aspirin-sensitive
              asthma. Monitor patients with preexisting asthma (without known
              aspirin sensitivity).
            </p>
            <p>
              <span className="underline">Serious Skin Reactions</span>: NSAIDS,
              including ketorolac, can cause serious skin reactions such as
              exfoliative dermatitis, Stevens-Johnson Syndrome (SJS), and toxic
              epidermal necrolysis (TEN), which can be fatal. These serious
              events may occur without warning. Discontinue SPRIX at first
              appearance of skin rash or other signs of hypersensitivity. SPRIX
              is contraindicated in patients with previous serious skin
              reactions to NSAIDs.
            </p>
            <p>
              <span className="underline">Fetal Toxicity</span>:{' '}
              <span className="bold">Limit use</span> of NSAIDs, including
              SPRIX, between about 20 to 30 weeks in pregnancy due to the risk
              of oligohydramnios/fetal renal dysfunction.{' '}
              <span className="bold">Avoid use</span> of NSAIDs in women at
              about 30 weeks gestation and later in pregnancy due to risks of
              oligohydramnios/fetal renal dysfunction and premature closure of
              the fetal ductus arteriosus.
            </p>
            <p>
              <span className="underline">
                Drug Rash with Eosinophilia and Systemic Symptoms (DRESS)
              </span>
              : Cases of DRESS, some fatal or life-threatening, have been
              reported in patients taking NSAIDs, such as SPRIX. Typical
              presentation of DRESS includes fever, rash, lymphadenopathy,
              and/or facial swelling. Other manifestations may include
              hepatitis, nephritis, hematological abnormalities, myocarditis, or
              myositis. DRESS may resemble an acute viral infection.
              Eosinophilia is often present. If any signs or symptoms of DRESS
              occur, discontinue SPRIX and evaluate the patient immediately.
            </p>
            <p>
              <span className="underline">Hematologic Toxicity</span>: Anemia
              has occurred in patients treated with NSAIDS. Monitor hemoglobin
              or hematocrit in patients with any signs or symptoms of anemia. Do
              not use SPRIX in patients for whom hemostasis is critical.
            </p>
            <p>
              <span className="underline">
                Masking of Inflammation and Fever
              </span>
              : The pharmacological activity of SPRIX in reducing inflammation,
              and possibly fever, may diminish the utility of diagnostic signs
              in detecting infections.
            </p>
            <p>
              <span className="underline">Laboratory Monitoring</span>: Because
              serious GI bleeding, hepatoxicity, and renal injury can occur
              without warning symptoms or signs, consider monitoring patients on
              long-term NSAID treatment with a CBC and a chemistry profile
              periodically.
            </p>
            <p>
              <span className="underline">Eye Exposure</span>: Avoid contact of
              SPRIX with the eyes. If eye contact occurs, wash out the eye with
              water or saline, and consult a physician if irritation persists
              for more than an hour.
            </p>
            <p>
              <span className="underline">Limitations of Use</span>: The total
              duration of use of SPRIX alone or sequentially with other forms of
              ketorolac is not to exceed 5 days. SPRIX should not be used
              concomitantly with other forms of ketorolac, or other NSAIDs.
            </p>

            <h4 className="fs-12 mt-25">Drug Interactions</h4>
            <p>
              <span className="underline">
                Drugs that interfere with hemostasis
              </span>
              : increased risk of serious bleeding with use of anticoagulants,
              antiplatelet agents, selective serotonin reuptake inhibitors
              (SSRIs), and serotonin norepinephrine reuptake inhibitors (SNRIs);
              concomitant use with pentoxifylline is contraindicated.
            </p>
            <p>
              <span className="underline">
                ACE inhibitors, angiotensin receptor blockers (ARBs), and
                beta-blockers
              </span>
              : may diminish the antihypertensive effect of these drugs; monitor
              blood pressure.{' '}
            </p>
            <p>
              <span className="underline">ACE Inhibitors and ARBs</span>: In
              elderly, volume depleted, or those with renal impairment may
              result in deterioration of renal function; monitor for signs of
              worsening renal function.
            </p>
            <p>
              <span className="underline">Diuretics</span>: reduces the
              natriuretic effect of loop diuretics (e.g., furosemide) and
              thiazide diuretics in some patients. During concomitant use of
              SPRIX with diuretics look for signs of worsening renal function
              and assure diuretic efficacy and antihypertensive effects.
            </p>
            <p>
              <span className="underline">Digoxin</span>: has been reported to
              increase the serum concentration and prolong the half-life of
              digoxin, monitor serum digoxin levels.
            </p>
            <h4 className="fs-12 mt-25">Adverse Reactions</h4>
            <p>
              The most common adverse reactions (incidence ≥2%) in patients
              treated with SPRIX and occurring at a rate at least twice that
              with placebo include: nasal discomfort; rhinalgia; increased
              lacrimation; throat irritation; oliguria; rash; bradycardia;
              decreased urine output; increased ALT and/or AST; hypertension;
              rhinitis.
            </p>
            <h4 className="fs-12 mt-25">Use in Specific Populations</h4>
            <p>
              <span className="underline">Pregnancy</span>: Use of NSAIDs,
              including SPRIX, can cause premature closure of the fetal ductus
              arteriosus and fetal renal dysfunction leading to oligohydramnios
              and, in some cases, neonatal renal impairment. Because of these
              risks, <span className="bold">limit</span> dose and duration of
              SPRIX use between about 20 and 30 weeks of gestation, and{' '}
              <span className="bold">avoid</span> SPRIX use at about 30 weeks of
              gestation and later in pregnancy.
            </p>
            <p>
              <span className="underline">Infertility</span>: NSAIDs are
              associated with reversible infertility. Consider withdrawal of
              SPRIX in women who have difficulties conceiving.
            </p>
            <h4 className="smallHeadline fs-12 mt-25">
              Please see{' '}
              <a
                className="utility-link"
                target={'_blank'}
                href={
                  'https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=97005a1c-167e-4676-bae7-e49b38c36f9e'
                }
              >
                Full Prescribing Information
              </a>
              , including BOXED WARNING and MEDICATION GUIDE.
            </h4>
            <h4 className="smallHeadline fs-12 mt-25">
              To report SUSPECTED ADVERSE REACTIONS, contact Assertio
              Therapeutics at <nobr>1-800-518-1084</nobr> or FDA at{' '}
              <nobr>1-800-FDA-1088</nobr> or{' '}
              <br className="hidden-smaller-screen" />{' '}
              <a
                className="utility-link"
                target={'_blank'}
                href={'http://www.fda.gov/medwatch'}
              >
                ww.fda.gov/medwatch
              </a>
              .
            </h4>
          </section>
          <HcpReferences />
        </div>
      </div>
    </>
  );
}
