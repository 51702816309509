import React, { useContext } from 'react';
import { Link } from 'gatsby';

import Nav from '../nav';
import { Logo } from 'components';
import { appContext } from 'providers/appProvider';

import './patient-header-content.scss';

const navUpperHeaderContent = () => (
  <div className="header-upper-content-container">
    <div className="header-upper-content">
      <Link to="/patient/important-safety-information">
        Important Safety Information
      </Link>
      <a
        href="https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=97005a1c-167e-4676-bae7-e49b38c36f9e"
        target="_blank"
        className="full-prescribing-information"
      >
        Full Prescribing Information{' '}
      </a>
      <a
        href="https://dailymed.nlm.nih.gov/dailymed/medguide.cfm?setid=97005a1c-167e-4676-bae7-e49b38c36f9e"
        target={'_blank'}
      >
        Medication Guide
      </a>
    </div>
  </div>
);

export const PatientHeaderContent = props => {
  const { appConfigs } = useContext(appContext);
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  return (
    <>
      <Logo url="/patient" className="sprix-logo" />
      <div className="nav-content-container">
        <div className="upper-container">{navUpperHeaderContent()}</div>
        <Nav indication={siteIndications.patient} />
      </div>
    </>
  );
};
