export const pages = {
  '/patient': [
    {
      name: 'OVERVIEW',
      url: '#overview',
    },
  ],
  '/patient/what-are-nsaids': [],
  '/patient/about-sprix': [
    {
      name: 'WHAT IS SPRIX<sup>&reg;</sup>',
      url: '#what-is-sprix',
    },
    {
      name: 'DOSING',
      url: '#dosing',
    },
    {
      name: 'HOW TO USE',
      url: '#how-to-use',
    },
  ],
  '/patient/sprix-direct': [],
  '/patient/resources': [
    {
      name: 'DOWNLOADABLE RESOURCES',
      url: '#downloads',
    },
    {
      name: 'VIDEO',
      url: '#video',
    },
  ],
  'sprix-direct': [
    {
      name: 'REQUEST A REP',
      url: '#rep',
    },
    {
      name: 'Cardinal Health Specialty Pharmacy',
      url: '#cardinal',
    },
    {
      name: 'CONTACT US',
      url: '#contact',
    },
  ],
  'patient/get-sprix-now': [],
  'patient/sitemap': [],
  'patient/important-safety-information': [],
};
