import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

export default function Logo({ className, url, ...props }) {
  return (
    <Link to={url} className={`logo ${className}`}>
      <StaticImage
        src="../../images/logo.png"
        alt="Logo"
        className="main-logo"
      />
    </Link>
  );
}

Logo.propTypes = {
  image: PropTypes.string.isRequired,
  url: PropTypes.string,
  className: PropTypes.string,
};
