import React, { useContext } from 'react';
import { Link } from 'gatsby';

import { Logo } from 'components';
import Nav from '../nav';
import { Button } from 'components/button';
import { appContext } from 'providers/appProvider';

import './hcp-header-content.scss';

const navUpperHeaderContent = () => {
  const RequestTeleRepBtn = () => (
    <div className="btn-container">
      <Button href="/get-in-touch/#rep" value="REQUEST TELEREP" />
    </div>
  );

  const UpperContent = () => (
    <div className="header-upper-content-container">
      <div className="header-upper-content">
        <Link to="/important-safety-information">
          Important Safety Information
        </Link>
        <a
          href="https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=97005a1c-167e-4676-bae7-e49b38c36f9e"
          target="_blank"
        >
          Full Prescribing Information{' '}
        </a>
      </div>
      <div className="hcp-site-content standard-bottom-spacing">
        This site is intended for US Healthcare professionals only.
      </div>
    </div>
  );

  return (
    <>
      <div className="upper-container">
        <RequestTeleRepBtn />
        <UpperContent />
      </div>
    </>
  );
};

export const HcpHeaderContent = props => {
  const { appConfigs } = useContext(appContext);
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  return (
    <>
      <Logo url="/" className="sprix-logo" />
      <div className="nav-content-container">
        {navUpperHeaderContent()}
        <Nav indication={siteIndications.hcp} />
      </div>
    </>
  );
};
