import React, { useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { Logo } from 'components';
import { appContext } from 'providers/appProvider';

const FooterLogo = () => {
  return (
    <StaticImage
      src="../../../../images/assertio-logo.png"
      className="client-logo"
      alt="Assertio Logo"
      placeholder="none"
    />
  );
};

const FooterUtilityNav = ({ className, indication, ...props }) => {
  const { appConfigs } = useContext(appContext);
  const siteIndication = appConfigs.siteOptions.INDICATIONS;
  return (
    <div {...props} className={className}>
      <div className="no-pb">
        <ul className="utility-list">
          <li>
            <Link
              to={
                siteIndication.hcp === indication
                  ? '/sitemap'
                  : '/patient/sitemap'
              }
            >
              Site Map
            </Link>
          </li>
          <span className="pipe-separator">|</span>
          <li>
            <a href="https://www.assertiotx.com/terms-of-use/" target="_blank">
              Terms of Use
            </a>
          </li>
          <span className="pipe-separator">|</span>
          <li>
            <a
              href="https://www.assertiotx.com/privacy-policy/"
              target="_blank"
            >
              Privacy Policy
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

const PatientFooter = props => {
  const { appConfigs } = useContext(appContext);
  const indication = props.indication;
  return (
    <div className="main-footer-content-wrapper">
      <div className="gutter-all footer-content">
        <div className="inner-content">
          <div className="logo-copyright">
            <div className="footer-logo">
              <FooterLogo />
            </div>
            <p className="copyright-text">
              &copy; November 2021, Assertio Therapeutics, Inc. <br />
              All rights reserved. {appConfigs.metaData.PATIENT_JOB_CODE}
            </p>
          </div>
          <FooterUtilityNav
            className="footer-utility-nav"
            indication={indication}
          />
        </div>
      </div>
    </div>
  );
};

const HcpFooter = props => {
  const { appConfigs } = useContext(appContext);
  const indication = props.indication;
  return (
    <div className="main-footer-content-wrapper">
      <div className="gutter-all footer-content">
        <div className="inner-content">
          <div className="logo-copyright">
            <div className="footer-logo">
              <FooterLogo />
            </div>
            <p className="copyright-text">
              &copy; November 2021, Assertio Therapeutics, Inc. <br />
              All rights reserved. {appConfigs.metaData.HCP_JOB_CODE}
            </p>
          </div>

          <FooterUtilityNav
            className="footer-utility-nav"
            indication={indication}
          />
        </div>
      </div>
    </div>
  );
};

export default function Footer({ indication, ...props }) {
  return (
    <footer {...props} className="footer-wrapper">
      {indication === 'hcp' && <HcpFooter indication={indication} />}
      {indication === 'patient' && <PatientFooter indication={indication} />}
    </footer>
  );
}

Footer.propTypes = {
  indication: PropTypes.string.isRequired,
};
