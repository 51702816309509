import React, { useState, useEffect, useContext } from 'react';
import parse from 'html-react-parser';
import { isEmpty } from 'lodash';

import { pages } from 'libs/patientNavLinks';
import { findCurrentPathname } from 'libs/utilityHelper';
import { appContext } from 'providers/appProvider';

import './PatientNav.scss';

export const PatientSubNav = props => {
  const [currentPage, setCurrentPage] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const { navWidth } = useContext(appContext);

  useEffect(() => {
    setIsLoaded(true);
    const currentPagePath = findCurrentPathname();
    if (
      currentPagePath &&
      currentPagePath[currentPagePath.length - 1] === '/'
    ) {
      setCurrentPage(currentPagePath.substring(0, currentPagePath.length - 1));
    } else {
      setCurrentPage(currentPagePath);
    }
  }, []);

  useEffect(() => {}, []);

  const calculateStyle = {
    width: `{$navWidth}px`,
    position: 'relative',
    left:
      typeof window !== 'undefined' && window.innerWidth > 1200
        ? `${1200 - navWidth}px`
        : typeof window !== 'undefined' && `${window.innerWidth - navWidth}px`,
    marginLeft: '-16px',
  };

  const renderPatientNavs = () => {
    const linksToRender = pages[currentPage];
    return !isEmpty(linksToRender) ? (
      <ul style={calculateStyle}>
        {linksToRender.map(link => (
          <li key={`${link.url} - ${link.name}`}>
            <a href={link.url}>{parse(link.name)}</a>
          </li>
        ))}
      </ul>
    ) : (
      <></>
    );
  };

  return (
    <div className="sub-nav-container">
      <div className="sub-nav-inner-container">
        <>{isLoaded && renderPatientNavs()}</>
      </div>
    </div>
  );
};
