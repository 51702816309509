export const pages = {
  '/': [
    {
      name: 'ABOUT SPRIX',
      url: '#about',
    },
    {
      name: 'PATIENT PROFILES',
      url: '#profiles',
    },
  ],
  '/clinical-data': [
    {
      name: 'RAPID ABSORPTION',
      url: '#rapid',
    },
    {
      name: 'Proven Efficacy',
      url: '#proven',
    },
    {
      name: 'Safety',
      url: '#safety',
    },
  ],
  '/prescribing-sprix': [
    {
      name: 'DOSING',
      url: '#dosing',
    },
    {
      name: 'STORAGE',
      url: '#storage',
    },
    {
      name: 'SPRIX DIRECT',
      url: '#direct',
    },
    {
      name: 'WAYS TO PRESCRIBE',
      url: '#prescribe',
    },
  ],
  '/resources': [],
  '/videos': [],
  '/get-in-touch': [
    {
      name: 'REQUEST A REP',
      url: '#rep',
    },
    {
      name: 'SPRIX DIRECT',
      url: '#sprix-direct',
    },
    {
      name: 'CONTACT US',
      url: '#contact',
    },
  ],
  '/sitemap': [],
  '/important-safety-information': [],
};
