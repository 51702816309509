import React from 'react';
import PropTypes from 'prop-types';

export default function Body({ indication, children, ...props }) {
  return (
    <div {...props} className="body-wrapper">
      {children}
    </div>
  );
}

Body.propTypes = {
  indication: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
