import React, { useState, useEffect, useContext } from 'react';
import parse from 'html-react-parser';
import { pages } from 'libs/hcpNavLinks';
import { findCurrentPathname } from 'libs/utilityHelper';
import { isEmpty } from 'lodash';
import { appContext } from 'providers/appProvider';

import './HCPNav.scss';

export const HcpSubNav = props => {
  const [currentPage, setCurrentPage] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const { navWidth } = useContext(appContext);

  useEffect(() => {
    const currentPagePath = findCurrentPathname();
    setIsLoaded(true);
    if (
      currentPagePath &&
      currentPagePath !== '/' &&
      currentPagePath[currentPagePath.length - 1] === '/'
    ) {
      setCurrentPage(currentPagePath.substring(0, currentPagePath.length - 1));
    } else {
      setCurrentPage(currentPagePath);
    }

    return () => {
      setIsLoaded(false);
    };
  }, []);

  const calculateStyle = {
    width: `{$navWidth}px`,
    position: 'relative',
    left:
      typeof window !== 'undefined' && window.innerWidth > 1200
        ? `${1200 - navWidth}px`
        : typeof window !== 'undefined' && `${window.innerWidth - navWidth}px`,
    marginLeft: '-16px',
  };

  const renderHCPNavs = () => {
    const linksToRender = pages[currentPage];
    return !isEmpty(linksToRender) ? (
      <ul style={calculateStyle}>
        {linksToRender.map(link => (
          <li key={`${link.url} - ${link.name}`}>
            <a href={link.url}>{parse(link.name)}</a>
          </li>
        ))}
      </ul>
    ) : (
      <></>
    );
  };
  return (
    isLoaded && (
      <div className="sub-nav-container">
        <div className="sub-nav-inner-container">
          <>{renderHCPNavs()}</>
        </div>
      </div>
    )
  );
};
