import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'gatsby';
import { useResizeObserver } from 'hooks';
import { appContext } from 'providers/appProvider';

export default function HcpNav({ triggerClose, ...props }) {
  const mobileBreakPoint = 991;
  const { updateNavWidth } = useContext(appContext);
  const [homeActive, setHomeActive] = useState(false);
  const [clinicalDataActive, setClinicalDataActive] = useState(false);
  const [prescribingActive, setPrescribingActive] = useState(false);
  const [toolsActive, setToolsActive] = useState(false);
  const [videosActive, setVideosActive] = useState(false);
  const [getInTouchActive, setGetInTouchActive] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const navRef = useRef(null);
  const elementSize = useResizeObserver(navRef);

  const isMobile =
    typeof window !== 'undefined' && window.innerWidth < mobileBreakPoint;

  const handleClick = () => {
    triggerClose && triggerClose();
  };
  const getCurrentPath = () =>
    typeof window !== 'undefined' && window.location.pathname;

  const isHomeActive = () =>
    ['/'].includes(getCurrentPath()) && getCurrentPath() !== '/';

  const isClinicalDataActive = () =>
    ['/clinical-data/', '/clinical-data'].includes(getCurrentPath()) &&
    getCurrentPath() !== '/';

  const isPrescribingActive = () =>
    ['/prescribing-sprix/', '/prescribing-sprix'].includes(getCurrentPath()) &&
    getCurrentPath() !== '/';

  const isToolsActive = () =>
    ['/resources/', '/resources'].includes(getCurrentPath()) &&
    getCurrentPath() !== '/';

  const isVideosActive = () =>
    ['/videos/', '/videos'].includes(getCurrentPath()) &&
    getCurrentPath() !== '/';

  const isGetInTouchActive = () =>
    ['/get-in-touch/', '/get-in-touch'].includes(getCurrentPath()) &&
    getCurrentPath() !== '/';

  useEffect(() => {
    setHomeActive(isHomeActive());
    setClinicalDataActive(isClinicalDataActive());
    setPrescribingActive(isPrescribingActive());
    setToolsActive(isToolsActive());
    setVideosActive(isVideosActive());
    setGetInTouchActive(isGetInTouchActive());
    setIsLoaded(prevState => {
      return true;
    });
    isLoaded && elementSize?.current && updateNavWidth(elementSize.width);
    return () => {
      setIsLoaded(false);
    };
  }, []);

  useEffect(() => {
    setHomeActive(isHomeActive());
    setClinicalDataActive(isClinicalDataActive());
    setPrescribingActive(isPrescribingActive());
    setToolsActive(isToolsActive());
    setVideosActive(isVideosActive());
    setGetInTouchActive(isGetInTouchActive());
  });

  useEffect(() => {
    isLoaded && elementSize?.width && updateNavWidth(elementSize.width);
  }, [elementSize.width]);

  const renderNav = () => {
    return (
      <nav className="main-nav hcp-nav" ref={navRef}>
        <p className="gutter-all no-pt hidden-desktop">
          This site is intended for US <br />
          healthcare professionals only.
        </p>
        <Link
          to="/"
          className={
            homeActive
              ? 'nav-item no-border-top active'
              : 'nav-item no-border-top'
          }
          activeClassName="active"
          onClick={handleClick}
        >
          HOME
        </Link>
        <span className="pipe-separator">|</span>
        <Link
          to="/clinical-data"
          className={clinicalDataActive ? 'nav-item active' : 'nav-item'}
          activeClassName="active"
          onClick={handleClick}
        >
          CLINICAL DATA
        </Link>
        <span className="pipe-separator">|</span>
        <Link
          to="/prescribing-sprix"
          className={prescribingActive ? 'nav-item active' : 'nav-item'}
          activeClassName="active"
          onClick={handleClick}
        >
          PRESCRIBING
        </Link>
        <span className="pipe-separator">|</span>
        <Link
          to="/resources"
          className={toolsActive ? 'nav-item active' : 'nav-item'}
          activeClassName="active"
          onClick={handleClick}
        >
          TOOLS
        </Link>
        <span className="pipe-separator">|</span>
        <Link
          to="/videos"
          className={videosActive ? 'nav-item active' : 'nav-item'}
          activeClassName="active"
          onClick={handleClick}
        >
          VIDEOS
        </Link>
        <span className="pipe-separator">|</span>
        <Link
          to="/get-in-touch"
          className={
            getInTouchActive
              ? 'nav-item no-padding-right mr-0 active'
              : 'nav-item no-padding-right mr-0'
          }
          // className="nav-item no-padding-right mr-0"
          activeClassName="active"
          onClick={handleClick}
        >
          GET IN TOUCH
        </Link>
        {/* <span className="pipe-separator hidden-desktop"></span> */}
        <div className="request-telerep hidden-desktop">
          <Link
            to="/get-in-touch/#rep"
            className="nav-item"
            activeClassName="active"
            onClick={handleClick}
          >
            REQUEST TELEREP
          </Link>
        </div>
        <span className="pipe-separator hidden-desktop"></span>
        <Link
          to="/important-safety-information"
          className="nav-item hidden-desktop no-border-bottom"
          activeClassName="active"
          onClick={handleClick}
        >
          Important Safety Information
        </Link>
        <a
          href="https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=97005a1c-167e-4676-bae7-e49b38c36f9e"
          target="_blank"
          className="nav-item no-border-bottom no-border-top hidden-desktop"
          activeClassName="active"
          onClick={handleClick}
        >
          Full Prescribing Information
        </a>
      </nav>
    );
  };

  return isMobile ? isLoaded && renderNav() : renderNav();
}
