import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { appContext } from 'providers/appProvider';

/**
 *
 * pageTitle: String (Required)
 * pageDescription: String (Optional)
 * pageKeywords: Array of Strings (Optional)
 * ##Note## Optional values, if not provided, will be used from app.settings.js
 *
 * Example:
 * import { Seo } from "../codes/components";
 *
 * <Seo pageTitle="home" />
 */

export default function Seo({
  pageTitle,
  pageDescription,
  pageKeywords,
  indication,
}) {
  const { appConfigs } = useContext(appContext);
  const siteIndication = appConfigs.siteOptions.INDICATIONS;
  const isHCP = siteIndication.hcp === indication;
  const selecedtTitle = isHCP
    ? appConfigs.metaData.SITE_DEFAULT_HCP_TITLE
    : appConfigs.metaData.SITE_DEFAULT_PATIENT_TITLE;
  const selectedDescription = isHCP
    ? appConfigs.metaData.SITE_DEFAULT_HCP_DESCRIPTION
    : appConfigs.metaData.SITE_DEFAULT_PATIENT_DESCRIPTION;

  const metaPageTitle = pageTitle
    ? `${appConfigs.metaData.SITE_NAME} | ${pageTitle}`
    : selecedtTitle;
  const metaDescription = pageDescription || selectedDescription;
  const metaKeywords =
    pageKeywords || appConfigs.metaData.SITE_DEFAULT_KEYWORDS;

  return (
    <Helmet
      title={metaPageTitle}
      // titleTemplate={`${appConfigs.metaData.SITE_NAME} | %s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaPageTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: metaPageTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(
        metaKeywords.length > 0
          ? {
              name: `keywords`,
              content: metaKeywords.join(`, `),
            }
          : [],
      )}
    />
  );
}

Seo.propTypes = {
  pageDescription: PropTypes.string,
  pageKeywords: PropTypes.arrayOf(PropTypes.string),
  pageTitle: PropTypes.string.isRequired,
};
